import { RouteItem } from '../../types/router';

export default function createRoutesMap(routes: RouteItem[]): Record<string, string> {
  return Object.fromEntries(createMap(routes));
}

function createMap(routes: RouteItem[]): Array<Array<string>> {
  return routes.reduce<string[][]>((map, route) => {
    let result = [[route.name, route.path]];

    if (route.routes) {
      result = result.concat(createMap(routes));
    }

    return map.concat(result);
  }, []);
}
