import { lazy } from 'react';

import { RouteItem } from '../core/types/router';
import createRoutesMap from '../core/router/utils/create-routes-map';
import createRoutesByPath from '../core/router/utils/create-routes-by-path';

export const routes: RouteItem[] = [
  {
    name: 'root',
    path: '/',
    exact: true,
    redirect: '/json-viewer'
  },
  {
    name: 'jsonViewer',
    path: '/json-viewer',
    exact: true,
    component: lazy(() => import('./json-viewer/json-viewer')),
    meta: {
      title: 'JSON Viewer'
    }
  },
  {
    name: 'colorPaletteGenerator',
    path: '/color-palette-generator',
    exact: true,
    component: lazy(() => import('./color-palette-generator/color-palette-generator')),
    meta: {
      title: 'Color Palette'
    }
  }
];

export const routesMap = createRoutesMap(routes);
export const routesByPath = createRoutesByPath(routes);
export const externalRoutesMap = {};
