import { RouteItem } from '../../types/router';

export default function createRoutesByPath(
  routes: RouteItem[]
): Record<string, Exclude<RouteItem, 'component' | 'routes'>> {
  return routes.reduce((map, routeItem) => {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const { component, ...route } = routeItem;
    let result = { ...map, [route.path]: route };

    if (route.routes) {
      result = { ...result, ...createRoutesByPath(route.routes) };
    }

    return result;
  }, {});
}
