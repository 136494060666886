import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { routes } from './router-config';
import Router from '../core/router/router';

function DevSuite() {
  return (
    <BrowserRouter>
      <Router routes={routes} />
    </BrowserRouter>
  );
}

export default DevSuite;
