import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { RouteItem } from '../types/router';
import RouteGuard from './route-guard';

export interface RouterProps {
  routes: RouteItem[];
  isAuthenticated?: boolean;
}

export default function Router({ routes, isAuthenticated = false }: RouterProps) {
  return (
    <Suspense fallback={null}>
      <Switch>
        {routes.map((route: RouteItem) => (
          <Route
            key={route.name}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <RouteGuard route={route} isAuthenticated={isAuthenticated} {...props} />
            )}
          />
        ))}
      </Switch>
    </Suspense>
  );
}
