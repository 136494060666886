import React from 'react';
import { Redirect } from 'react-router-dom';

import { RouteItem } from '../types/router';

interface RouteGuardProps {
  route: RouteItem;
  isAuthenticated?: boolean;
  loginRoute?: RouteItem;
}

RouteGuard.defaultProps = {
  loginRoute: null,
  isAuthenticated: false
};

export default function RouteGuard(props: RouteGuardProps) {
  const { route, isAuthenticated, loginRoute, ...rest } = props;

  if (route.private && !isAuthenticated && loginRoute) {
    return <Redirect to={loginRoute?.path} />;
  }

  if (route.component) {
    return <route.component {...rest} routes={route.routes} meta={route.meta} />;
  }

  if (route.redirect) {
    return <Redirect exact={route.exact} from={route.path} to={route.redirect} />;
  }

  return <Redirect to="/" />;
}
